/* eslint-disable jsx-a11y/anchor-is-valid */
import "./footer.css";
// import Phone from "../../assets/footerPhone.png";
import Phone from "../../assets/iPhone.svg";
import CBN from "../../assets/cbn.svg";
import apple from "../../assets/appleicon.png";
import play from "../../assets/playicon.png";
import Instagram from "../../assets/instagram.svg";
import Facebook from "../../assets/facebook.svg";
import Twitter from "../../assets/twitter.webp";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-head">
        <h2>Access simple, secure & reliable financial solutions now!!!</h2>
        <div className="footer-head-body">
          <img src={Phone} className="md:!w-1/3" alt="phone" />
          <div>
            <h3 className="download">
              Download the Fast Credit App and get signed up in a few minutes.
            </h3>
            <div className="license">
              <div className="cbn !mx-auto">
                <p>Licensed by CBN</p>
                <img src={CBN} alt="cbn" />
              </div>
            </div>
            <div className="flex flex-col items-center justify-evenly space-y-4 sm:flex-row md:my-10 lg:mt-8">
              <a
                className="foot__play "
                href="https://play.google.com/store/apps/details?id=com.fastcredit"
                target="_blank"
                rel="noreferrer"
              >
                {/* <img
                  src={Googleplay}
                  className="ft__play !w-1/3  lg:!w-1/2 xl:!w-full "
                  alt="google"
                /> */}
                <img src={play} className="" alt="play" />
              </a>
              <a className="apple" href="" target="_blank" rel="noreferrer">
                {/* <img
                  className="ft__apple lg:!-ml-28 xl:!ml-10 !w-1/3 lg:!w-1/2 xl:!w-full"
                  src={Apple}
                  alt="apple"
                /> */}
                <img src={apple} className="md:!-mt-4 " alt="apple" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-body">
        <div className="footer-single">
          <div className="footer-nav">
            <p>Our Products</p>
            <Link>Payments</Link>
            <Link to="/loans/group">Loans</Link>
            <Link to="/investments/target">Investments</Link>
            <Link to="/account"> Open Account</Link>
          </div>
          <div className="footer-nav">
            <p>About Fast Credit</p>
            <Link>News & Community</Link>
            <Link>Careers</Link>
            <Link to="/faq">FAQ</Link>
          </div>
          <div className="footer-nav">
            <p>Our Policies </p>
            <Link to="/terms">Terms & Conditions</Link>
            <Link to="/policy">Privacy policy </Link>
            <Link to="/cookies">Cookies policy </Link>
            {/* <Link to="/.">Sitemap </Link> */}
          </div>
          <div className="footer-nav">
            <p>Locations</p>
            <Link to="/contact">Ikeja Lagos HQ </Link>
            <Link to="/contact">Ibadan</Link>
            <Link to="/contact">Akure</Link>
            <Link to="/contact">Abeokuta</Link>
            <Link to="/contact">Abuja</Link>
            <Link to="/contact">Victoria Island</Link>
            <Link to="/contact">Port Harcourt</Link>
          </div>
        </div>
        <div className="footer-socials">
          <p>@fastcreditltd</p>
          <div className="footer-socials-body">
            <a
              className="footer-socials-single"
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/fastcreditng/"
            >
              <img src={Instagram} alt="instagram" />
            </a>

            <a
              className="footer-socials-single"
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/FastCreditLimited/"
            >
              <img src={Facebook} alt="facebook" />
            </a>

            <a
              className="footer-socials-single "
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/Fastcreditng"
            >
              <img
                src={Twitter}
                className="w-3/4 !rounded-full"
                alt="twitter"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
