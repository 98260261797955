import "./input.css";
import Dropdown from "../../assets/arrow-down2.svg";

const Input = ({
  label,
  placeholder,
  type,
  dropdown,
  dropdownList,
  action,
  onChange,
  product,
  productStatus,
  dropdownAction,
  value,max
}) => {
  return (
    <>
      {dropdown ? (
        <div className="report-form ">
          <label>{label}</label>
          <div
            onClick={dropdownAction}
            className="!relative mt-5 h-11 cursor-pointer"
            style={{
              background: product ? "#F2FCF5" : "#f6f6f6",
              borderBottom: product ? "2px solid #029247" : "none",
            }}
          >
            <p>{product}</p>
            <div
              style={{
                background: product ? "#CCF2D5" : "#e9e9e9",
                borderBottom: product ? "1.5px solid #029247" : "none",
              }}
              className="dropdown-icon !h-11 rounded-br rounded-tr"
              onClick={dropdownAction}
            >
              <img src={Dropdown} alt="dropdown" />
            </div>
          </div>
          {productStatus ? (
            <div className=" flex !w-full flex-col !items-start rounded-lg shadow-lg">
              {dropdownList.map((item, index) => {
                return (
                  <p
                    className="drop-item !cursor-pointer hover:font-semibold hover:!text-black"
                    onClick={action}
                    key={index}
                  >
                    {item}
                  </p>
                );
              })}
            </div>
          ) : null}
        </div>
      ) : (
        <div className="report-form ">
          <label>{label}</label>
          <input
            className="remove-arrow input !appearance-none rounded"
            style={{
              borderBottom: value ? "2px solid #029247" : "none",
              background: value ? "#F2FCF5" : "#f6f6f6",
              outline: "none",
            }}
            type={type}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            max={max}
          />
        </div>
      )}
    </>
  );
};

export default Input;
