import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import "./account.css";
import { Investment } from "../../components/header/Investment";

export default function Account() {
  return (
    <>
      <Header />
      <div className="account">
        <Investment />
      </div>
      <Footer />
    </>
  );
}
