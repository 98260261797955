import { toast } from "react-hot-toast";
import classNames from "classnames";
import ToastIcon from "../assets/Toast";

export const Toast = ({ title, error }) =>
  toast.custom((t) => (
    <div
      className={classNames(
        "flex items-center space-x-2 rounded-lg px-6 py-4 capitalize text-white shadow-md",
        {
          "animate-enter": t.visible,
          "animate-leave": !t.visible,
          "bg-[#029247]": !error,
          "bg-[#FF3B30]": error,
        }
      )}
    >
      <ToastIcon />
      <span>{title}</span>
    </div>
  ));
