import { lazy } from "react";

const About = lazy(() => import("./about/about"));
const Calculator = lazy(() => import("./calculator/calculator"));
const Contact = lazy(() => import("./contact/contact"));
const Faq = lazy(() => import("./faq/Faq"));
const Investments = lazy(() => import("./investmens/investments"));
const Landing = lazy(() => import("./landing/landing"));
const Loans = lazy(() => import("./loans/loans"));
const Policy = lazy(() => import("./policy/Policy"));
const Term = lazy(() => import("./terms/terms"));
const BuyNow = lazy(() => import("./BuyNow"));
const TravelNow = lazy(() => import("./TravelNow"));

export {
  About,
  Calculator,
  Contact,
  Faq,
  TravelNow,
  BuyNow,
  Investments,
  Landing,
  Loans,
  Policy,
  Term,
};
