/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { postApi } from "../../utils/services";
import { Toast } from "../../components/Toast";
import axios from "axios";

export const useInvestment = () => {
  const [employerCode, setEmployerCode] = useState("");
  const [investStatus2, setInvestStatus2] = useState(false);
  const [investStatus, setInvestStatus] = useState(false);
  const [freqStatus, setFreqStatus] = useState(false);
  const [category, setCategory] = useState("90");
  const [investMenu, setInvestMenu] = useState("");
  const [investFreq, setInvestFreq] = useState("");
  const [investType, setInvestType] = useState("");

  const [investTypeList, setInvestTypeList] = useState([
    "Fast Credit Investment",
    "Target Savings",
  ]);
  const [investListMenu, setInvestListMenu] = useState([
    "FCL Note",
    "FCL Lock",
    "Kiddies Swag",
  ]);
  const [investFreqMenu, setInvestFreqMenu] = useState([
    "Daily",
    "Weekly",
    "Monthly",
    "Manually",
  ]);
  const [loading, setLoading] = useState(false);

  const [inputFile, setInputFile] = useState({
    name: "",
    email: "",
    phone: "",
    desc: "",
    bvn: "",
    startDate: "",
    dob: "",
    endDate: "",
    nin: "",
  });
  const [amount2, setAmount2] = useState("");
  const [amount, setAmount] = useState("");
  const [income, setIncome] = useState("");
  const [differenceInMonths, setDifferenceInMonths] = useState(null);

  const handleCalculate = () => {
    if (inputFile.startDate && inputFile.endDate) {
      getDaysDifference(
        new Date(inputFile.startDate),
        new Date(inputFile.endDate)
      );
    }
  };
  const getDaysDifference = (date1, date2) => {
    const timeDiff = date2?.getTime() - date1?.getTime();
    const daysDifference = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    setDifferenceInMonths(daysDifference);
    return daysDifference;
  };
  const calculateAmountFrequency = () => {
    const differenceInDays = getDaysDifference(
      new Date(inputFile.startDate),
      new Date(inputFile.endDate)
    );
    let amount_ = Number(amount?.replace(/,/g, ""));
    let weeklyFactor = Math.round(differenceInDays / 7);
    let monthlyFactor = Math.round(differenceInDays / 30);
    if (investFreq === "Daily") {
      setAmount2(amount_ / differenceInDays);
    } else if (investFreq === "Weekly") {
      setAmount2(amount_ / weeklyFactor);
    } else {
      setAmount2(amount_ / monthlyFactor);
    }
  };
  useEffect(() => {
    if (inputFile.startDate !== "" && inputFile.endDate !== "") {
      handleCalculate();
    }
  }, [inputFile.startDate, inputFile.endDate]);
  useEffect(() => {
    inputFile.startDate &&
      inputFile.endDate &&
      amount &&
      calculateAmountFrequency();
  }, [inputFile.startDate, inputFile.endDate, amount, investType, investFreq]);

  const addCommas = (num) =>
    num?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const removeNonNumeric = (num) => num?.toString()?.replace(/[^0-9]/g, "");

  const handleChange3 = (event) => {
    setAmount(addCommas(removeNonNumeric(event.target.value)));
  };
  const handleChange2 = (event) => {
    setIncome(addCommas(removeNonNumeric(event.target.value)));
  };
  const handleChange4 = (event) => {
    setAmount2(addCommas(removeNonNumeric(event.target.value)));
  };

  const handleChange = (e) => {
    setInputFile({
      ...inputFile,
      [e.target.name]: e.target.value,
    });
  };

  const submit = (e) => {
    //   setView(2);

    e.preventDefault();
    setLoading(true);
    const newPayload = {
      employee_no: inputFile.code,
      employer_name: employerCode,
      customer_name: inputFile.name,
      employer_code: employerCode,
      channel: "WEB",
      userid: "",
      address: "",
      religion: "",
      dob: "",
      gender: "",
      tel_no: inputFile.phone,
      marital_status: "",
      email_address: inputFile.email,
      means_identification: "",
      id_number: "",
      id_issue_date: "",
      id_expiry_date: "",
      name_nok: "",
      relationship_nok: "",
      phone_nok: "",
      loan_amt: amount,
      bvn: "",
      loan_tenure: "",
    };
    // console.log("payload", newPayload);
    if (
      inputFile.name &&
      inputFile.email &&
      inputFile.phone &&
      "product" &&
      amount
    ) {
      postApi(
        "https://apicore.fastcredit-ng.com/v1/fclapi/pushloans00",
        newPayload
      )
        .then((res) => {
          // console.log(res);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      Toast({ title: "Please fill all fields", error: true });

      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      totalAmount: amount,
      firstName: inputFile.name?.split(" ")[0],
      lastName: inputFile.name?.split(" ")[1],
      email: inputFile.email,
      nin: inputFile.nin,
      dob: inputFile.dob,
      phoneNumber: inputFile.phone,
      description: inputFile.desc,
      // savingsPeriod: Number(category),
      startDate: inputFile.startDate,
      endDate: inputFile.endDate,
      investmentType:
        investMenu === "FCL Note" ? 3 : investMenu === "Kiddies Swag" ? 2 : 4,
    };
    const payload2 = {
      totalAmount: amount,
      firstName: inputFile.name?.split(" ")[0],
      lastName: inputFile.name?.split(" ")[1],
      email: inputFile.email,
      nin: inputFile.nin,
      dob: inputFile.dob,
      phoneNumber: inputFile.phone,
      frequentAmount: Number(amount2)?.toFixed(2)?.toString(),
      description: inputFile.desc,
      startDate: inputFile.startDate,
      endDate: inputFile.endDate,
      investmentType: 1,
      paymentFrequency:
        investFreq === "Monthly"
          ? 3
          : investFreq === "Weekly"
          ? 2
          : investFreq === "Daily"
          ? 1
          : 0,
    };
    const payload_ = investType === "Target Savings" ? payload2 : payload;
    const onlyContainsNumbers = (str) => /^\d+$/.test(str);
    if (investType === "") {
      Toast({ title: "Select Category", error: true });
      return;
    }
    if (investMenu === "" && investType !== "Target Savings") {
      Toast({ title: "Select Investment type", error: true });
      return;
    }
    if (inputFile.name === "") {
      Toast({ title: "Input Name", error: true });
      return;
    }
    if (inputFile.desc === "") {
      Toast({ title: "Input Description", error: true });
      return;
    }
    if (inputFile.email === "") {
      Toast({ title: "Input Email", error: true });
      return;
    }
    if (amount === "") {
      Toast({ title: "Input amount", error: true });
      return;
    }
    if (amount2 === "" && investType === "Target Savings") {
      Toast({ title: "Input Frequent amount", error: true });
      return;
    }
    if (
      Number(amount?.replace(/,/g, "")) < 5000 &&
      investType === "Target Savings"
    ) {
      Toast({ title: "Minimum Target amount is NGN 5,000", error: true });
      return;
    }
    if (
      Number(amount?.replace(/,/g, "")) < 100000 &&
      investType !== "Target Savings"
    ) {
      Toast({ title: "Minimum amount is NGN 100,000", error: true });
      return;
    }
    if (amount2 === "" && investType === "Target Savings") {
      Toast({ title: "Input Frequent amount", error: true });
      return;
    }
    if (inputFile.email) {
      const isValidFormat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputFile.email);
      if (!isValidFormat) {
        Toast({ title: "Invalid Email", error: true });
        return;
      }
    }
    if (
      inputFile.bvn.length !== 11 ||
      onlyContainsNumbers(inputFile.bvn) === false
    ) {
      Toast({ title: "Invalid BVN", error: true });
      return;
    }
    if (
      inputFile.nin.length !== 11 ||
      onlyContainsNumbers(inputFile.nin) === false
    ) {
      Toast({ title: "Invalid NIN", error: true });
      return;
    }
    if (inputFile.phone === "") {
      Toast({ title: "Input phone number", error: true });
      return;
    }
    if (inputFile.bvn === "") {
      Toast({ title: "Input BVN number", error: true });
      return;
    }
    if (inputFile.nin === "") {
      Toast({ title: "Input NIN number", error: true });
      return;
    }
    if (
      inputFile.phone.length !== 11 ||
      onlyContainsNumbers(inputFile.phone) === false
    ) {
      Toast({ title: "Invalid phone number", error: true });
      return;
    }

    if (investType === "Target Savings" && inputFile.startDate === "") {
      Toast({
        title: "Input Start Date ",
        error: true,
      });
      return;
    }
    if (investType === "Target Savings" && inputFile.endDate === "") {
      Toast({
        title: "Input End Date ",
        error: true,
      });
      return;
    }
    if (investType === "Target Savings" && differenceInMonths < 30) {
      Toast({
        title: "Minimum duration is a month",
        error: true,
      });
      return;
    }
    if (
      investType !== "Target Savings" &&
      investMenu === "Kiddies Swag" &&
      differenceInMonths < 365
    ) {
      Toast({
        title: "Minimum duration is 12 months",
        error: true,
      });
      return;
    }
    if (
      investType !== "Target Savings" &&
      investMenu === "FCL Lock" &&
      differenceInMonths < 90
    ) {
      Toast({
        title: "Minimum duration is 3 months",
        error: true,
      });
      return;
    }
    if (
      investType !== "Target Savings" &&
      investMenu === "FCL Note" &&
      differenceInMonths < 90
    ) {
      Toast({
        title: "Minimum duration is 3 months",
        error: true,
      });
      return;
    }
    if (investType === "Target Savings" && inputFile.startDate === "") {
      Toast({
        title: "input Start Date",
        error: true,
      });
      return;
    }
    if (investType === "Target Savings" && inputFile.endDate === "") {
      Toast({
        title: "input End Date",
        error: true,
      });
      return;
    }
    if (inputFile.dob === "") {
      Toast({ title: "Input Date", error: true });
      return;
    } else {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_API_ACCOUNT}/CreateInvestment`,
          payload_,
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          }
        )
        .then((res) => {
          if (res.data.responseCode !== 200) {
            Toast({ title: res.data.responseMessage, error: true });
            setLoading(false);
            return;
          }

          Toast({ title: res.data.responseMessage, error: false });
          setInputFile({
            desc: "",
            nin: "",
            dob: "",
            phone: "",
            bvn: "",
            email: "",
            name: "",
            endDate: "",
            startDate: "",
          });
          setAmount("");
          setAmount2("");
          setInvestType("");
          setInvestFreq("");
          setInvestMenu("");
          setLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        });
    }
  };

  return {
    submit,
    inputFile,
    investStatus,
    amount,
    handleChange3,
    handleChange,
    income,
    loading,
    investTypeList,
    handleChange2,
    investType,
    employerCode,
    setEmployerCode,
    setInvestType,
    setInvestStatus,
    setInvestTypeList,
    setInvestListMenu,
    investListMenu,
    setInvestMenu,
    investMenu,
    setInvestStatus2,
    investStatus2,
    setInvestFreq,
    investFreq,
    setFreqStatus,
    freqStatus,
    setInvestFreqMenu,
    investFreqMenu,
    amount2,
    handleChange4,
    category,
    setCategory,
    handleSubmit,
  };
};
