import React from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import "./grant.css";
import GrantForm from "../../components/GrantForm"

export default function GrantApply() {
  return (
    <>
      <Header />
      <GrantForm />
      <Footer />
    </>
  );
}
