import { Suspense, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { api } from "./utils/services";
import ReactGA from "react-ga";
import {
  About,
  Calculator,
  Contact,
  Faq,
  Investments,
  Landing,
  Loans,
  Policy,
  TravelNow,
  Term,
  BuyNow,
} from "./pages";
import Loading from "./components/Loading";
import { ErrorBoundary } from "./error/errorBoundary";
import NotFound from "./pages/NotFound";
import Account from "./pages/account";
import CookiesPolicy from "./pages/policy/CookiePolicy";
import Cookies from "./components/Cookies";
import GrantApp from "./pages/grant";
import GrantApply from "./pages/grant/GrantApply";
function App() {
  const TRACKING_ID = "UA-171710801-1";
  ReactGA.initialize(TRACKING_ID);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    // console.log(
    //   "config",
    //   ReactGA.pageview(window.location.pathname + window.location.search)
    // );
    api();
  }, []);

  return (
    <div className="App">
      <Cookies />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Landing />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/loans/bnpl"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Loans page="buy" />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/loans/sector"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Loans />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/loans/sector/public"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Loans page="public" />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/loans/sector/private"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Loans page="private" />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/loans/sme"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Loans page="SME" />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/loans/invoice"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Loans page="invoice" />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/travelnow"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <TravelNow />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/buynow"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <BuyNow />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/loans/group"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Loans page="group" />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/loans/calculator"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Calculator />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/contact"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Contact />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/about"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <About />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/faq"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Faq />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/createinvestment"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Account />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/account*****888799"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Account />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/policy"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Policy />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/cookies"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <CookiesPolicy />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/terms"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Term />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/investments/target"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Investments page="target" />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/investments/kiddies"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Investments page="kiddies" />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/investments/fcl"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <Investments page="investments" />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/scholarship-application"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <GrantApp />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="/scholarship-application/apply"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <GrantApply />
                </Suspense>
              </ErrorBoundary>
            }
          />
          <Route
            path="*"
            element={
              <ErrorBoundary>
                <Suspense fallback={<Loading />}>
                  <NotFound />
                </Suspense>
              </ErrorBoundary>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
