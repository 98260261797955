/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from "react";
import { Toast } from "../../components/Toast";
import axios from "axios";

export default function useGrant() {
  const [view, setView] = useState(1);
  const [loading, setLoading] = useState(false);
  const sectionRef = useRef(null);
  const [file2, setFile2] = useState("");
  const [file3, setFile3] = useState("");
  const [file4, setFile4] = useState("");
  const [file5, setFile5] = useState("");
  const [file6, setFile6] = useState("");
  const [file7, setFile7] = useState("");
  const [file, setFile] = useState("");
  const hiddenFileInput = useRef(null);
  const hiddenFileInput2 = useRef(null);
  const hiddenFileInput3 = useRef(null);
  const hiddenFileInput4 = useRef(null);
  const hiddenFileInput5 = useRef(null);
  const hiddenFileInput7 = useRef(null);
  const hiddenFileInput6 = useRef(null);
  const [fileName2, setFileName2] = useState("");
  const [fileName3, setFileName3] = useState("");
  const [fileName4, setFileName4] = useState("");
  const [fileName5, setFileName5] = useState("");
  const [fileName6, setFileName6] = useState("");
  const [fileName7, setFileName7] = useState("");
  const [fileName, setFileName] = useState("");
  const [inputFile, setInputFile] = useState({
    fname: "",
    lname: "",
    email: "",
    phone: "",
    institution: "",
    study: "",
    age: "",
    studyYear: "",
    fcl: "",
    address: "",
    CGPA: "",
    parent: "",
    parentNo: "",
    extracurricular: "",
    experience: "",
    pgfcl: "",
  });
  const [levelStatus, setLevelStatus] = useState(false);
  const [genderStatus, setGenderStatus] = useState(false);
  const [relationStatus, setRelationStatus] = useState(false);
  const [relationMenu, setRelationMenu] = useState("");
  const [genderMenu, setGenderMenu] = useState("");
  const [levelMenu, setLevelMenu] = useState("");
  const [levelListMenu, setLevelListMenu] = useState([
    "100 Level",
    "200 Level",
    "300 Level",
    "400 Level",
    "500 Level",
  ]);
  const [genderListMenu, setGenderListMenu] = useState(["Male", "Female"]);
  const [relationListMenu, setRelationListMenu] = useState([
    "Parent",
    "Guardian",
  ]);
  const scrollToSection = () => {
    sectionRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleBase64 = (file, name, func) => {
    if (file.type !== "application/pdf") {
      Toast({ title: "Upload can only be a PDF format", error: true });
      return;
    } else {
      const res = name?.replace("data:application/pdf;base64,", "");
      func(res);
      return;
    }
  };

  const clipText = (name) => {
    return name?.length > 20 ? `${name.slice(0, 20)}...` : name;
  };

  const handleChange = (e) => {
    setInputFile({
      ...inputFile,
      [e.target.name]: e.target.value,
    });
  };
  const handleViewChange = (viewNumber) => {
    setView(viewNumber);
  };
  const handleSubmit = async (e) => {
    e?.preventDefault();
    setLoading(true);
    const payload = {
      firstName: inputFile.fname,
      lastName: inputFile.lname,
      age: Number(inputFile.age),
      gender: genderMenu,
      phoneNumber: inputFile.phone,
      email: inputFile.email,
      address: inputFile.address,
      fclAccount: inputFile.fcl,
      institution: inputFile.institution,
      course: inputFile.study,
      yearOfStudy: levelMenu,
      level: levelMenu,
      activities: inputFile.extracurricular,
      leadershipExperience: inputFile.experience,
      cgpa: Number(inputFile.CGPA),
      parentName: inputFile.parent,
      parentEmployeeNumber: inputFile.parentNo,
      parentFCLAccountNumber: inputFile.pgfcl,
      relationshipWithApplicant: relationMenu,
      transcript: fileName,
      letterOfRecommendation: fileName2,
      personalStatement: fileName3,
      studentId: fileName4,
      parentWorkId: fileName5,
      studentPassportPhoto: fileName6,
      letterOfAdmission: fileName7,
    };
    const res = await axios
      .post(
        `${process.env.REACT_APP_API_ACCOUNT}/api/Scholarship/ScholarshipApplication`,
        payload,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((res) => {
        if (res?.data?.responseCode === 200) {
          setView(5);
          setLoading(false);
          setInputFile({
            fname: "",
            lname: "",
            email: "",
            phone: "",
            institution: "",
            study: "",
            age: "",
            studyYear: "",
            fcl: "",
            address: "",
            CGPA: "",
            parent: "",
            parentNo: "",
            extracurricular: "",
            experience: "",
            pgfcl: "",
          });
          setRelationMenu("");
          setGenderMenu("");
          setLevelMenu("");
          return;
        } else if (res?.data?.responseCode === 422) {
          Toast({
            title: res.data?.responseMessage,
            error: true,
          });
          setLoading(false);
          return;
        }
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
        return;
      });
    setLoading(false);
  };
  useEffect(() => {
    scrollToSection();
  }, [view]);

  const handleClick2 = (event) => {
    hiddenFileInput2.current.click();
  };
  const handleClick3 = (event) => {
    hiddenFileInput3.current.click();
  };
  const handleClick4 = (event) => {
    hiddenFileInput4.current.click();
  };
  const handleClick5 = (event) => {
    hiddenFileInput5.current.click();
  };
  const handleClick6 = (event) => {
    hiddenFileInput6.current.click();
  };
  const handleClick7 = (event) => {
    hiddenFileInput7.current.click();
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleFileChange2 = (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    if (event.target.files[0]?.size > 3200000) {
      Toast({ title: "Maximum Size is 3MB", error: true });
      return;
    } else {
      setFile2(event.target.files[0]);
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          handleBase64(file, reader.result, setFileName2);
        };

        reader.readAsDataURL(file); // Convert file to Base64
      }
    }
  };
  const handleFileChange3 = (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    if (event.target.files[0]?.size > 3200000) {
      Toast({ title: "Maximum Size is 3MB", error: true });
      return;
    } else {
      setFile3(event.target.files[0]);
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onloadend = () => {
          handleBase64(file, reader.result, setFileName3);
        };

        reader.readAsDataURL(file); // Convert file to Base64
      }
    }
  };
  const handleFileChange4 = (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    if (event.target.files[0]?.size > 3200000) {
      Toast({ title: "Maximum Size is 3MB", error: true });
      return;
    } else {
      setFile4(event.target.files[0]);
      const file = event.target.files[0];

      if (file) {
        const reader = new FileReader();

        reader.onloadend = () => {
          handleBase64(file, reader.result, setFileName4);
        };

        reader.readAsDataURL(file); // Convert file to Base64
      }
    }
  };
  const handleFileChange5 = (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    if (event.target.files[0]?.size > 3200000) {
      Toast({ title: "Maximum Size is 3MB", error: true });
      return;
    } else {
      setFile5(event.target.files[0]);
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onloadend = () => {
          handleBase64(file, reader.result, setFileName5);
        };

        reader.readAsDataURL(file); // Convert file to Base64
      }
    }
  };
  const handleFileChange6 = (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    if (event.target.files[0]?.size > 3200000) {
      Toast({ title: "Maximum Size is 3MB", error: true });
      return;
    } else {
      setFile6(event.target.files[0]);
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onloadend = () => {
          handleBase64(file, reader.result, setFileName6);
        };

        reader.readAsDataURL(file); // Convert file to Base64
      }
    }
  };
  const handleFileChange7 = (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    if (event.target.files[0]?.size > 3200000) {
      Toast({ title: "Maximum Size is 3MB", error: true });
      return;
    } else {
      setFile7(event.target.files[0]);
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onloadend = () => {
          handleBase64(file, reader.result, setFileName7);
        };

        reader.readAsDataURL(file); // Convert file to Base64
      }
    }
  };
  const handleFileChange = (event) => {
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    if (event.target.files[0]?.size > 3200000) {
      Toast({ title: "Maximum Size is 3MB", error: true });
      return;
    } else {
      setFile(event.target.files[0]);
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();

        reader.onloadend = () => {
          handleBase64(file, reader.result, setFileName);
        };

        reader.readAsDataURL(file); // Convert file to Base64
      }
    }
  };

  return {
    scrollToSection,
    hiddenFileInput,
    file,
    setFile,
    sectionRef,
    view,
    inputFile,
    genderMenu,
    setGenderMenu,
    handleChange,
    handleViewChange,
    handleSubmit,
    handleFileChange,
    genderListMenu,
    setGenderListMenu,
    handleClick,
    fileName,
    genderStatus,
    setGenderStatus,
    file2,
    hiddenFileInput2,
    handleFileChange2,
    relationStatus,
    relationMenu,
    relationListMenu,
    setRelationMenu,
    setRelationStatus,
    loading,
    levelListMenu,
    setLevelListMenu,
    levelMenu,
    setLevelMenu,
    levelStatus,
    setLevelStatus,
    handleFileChange3,
    handleFileChange4,
    handleFileChange6,
    handleFileChange5,
    handleFileChange7,
    file3,
    file4,
    file5,
    file6,
    file7,
    handleClick2,
    handleClick3,
    handleClick4,
    handleClick5,
    handleClick6,
    handleClick7,
    hiddenFileInput3,
    hiddenFileInput4,
    hiddenFileInput5,
    hiddenFileInput6,
    hiddenFileInput7,
    clipText,
  };
}
