/* eslint-disable react-hooks/exhaustive-deps */
import CookieConsent from "react-cookie-consent";

const Cookies = () => {
  return (
    <div className="">
      <CookieConsent
        location="bottom"
        buttonText="I agree"
        cookieName="CookieConsent"
        declineButtonText="Decline"
        declineButtonStyle={{ fontSize: "14px", borderRadius: "7px" }}
        style={{ background: "#2B373B", zIndex: 1501 }}
        buttonStyle={{
          color: "#fff",
          fontSize: "14px",
          borderRadius: "7px",
          background: "#029247",
        }}
        expires={365}
        enableDeclineButton
        onDecline={() => {
          console.log("Cookies declined");
        }}
      >
        {" "}
        <p className="py-1 text-sm sm:px-8">
          we use cookies to enhance your browsing experience, serve personalized
          ads or content and analyze our traffic. By clicking agree, you consent
          to our
          <a
            className=" text-green-500 underline"
            target="_blank"
            href="/cookies"
            style={{
              marginLeft: "2px",
            }}
          >
            use of cookies.
          </a>
        </p>
        <br />
        <br />
        <br />
      </CookieConsent>
    </div>
  );
};

export default Cookies;
