/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useLayoutEffect } from "react";
import "./header.css";
import Logo2 from "../../assets/Logo2.svg";
import Menu from "../../assets/menu.svg";
import Times from "../../assets/times.svg";
import WhiteLogo from "../../assets/whiteLogo.svg";
import ArrowUp from "../../assets/arrow-up.svg";
import ArrowDown from "../../assets/arrow-down.svg";
import ArrowDown2 from "../../assets/arrow-down2.svg";
import { NavLink, Link, useLocation } from "react-router-dom";
import { links } from "../../data/data";
import OutsideClick from "../outsideClick/outsideClick";
import CustomModal from "./CustomModal";
import { Investment } from "./Investment";

const Header = () => {
  const [click, setClick] = useState(true);
  const [loans, setLoans] = useState(false);
  const [investments, setInvestments] = useState(false);
  const pathname = window.location.pathname.replace("/", "");
  const [openModal, setOpenModal] = useState(false);
  const device = localStorage.getItem("device");

  const handle = (item) => {
    item && setOpenModal((prev) => !prev);
    setInvestments(!investments);
    setClick(true);
  };
  const handleClose = () => setOpenModal(false);
  // const navigate = useNavigate();
  // const buyNow = () => navigate("/buynow");
  const changeLink = (title) => {
    if (title === "Loans") {
      setLoans(!loans);
      setInvestments(false);
      setClick(true);
    } else if (title === "Investments") {
      setInvestments(!investments);
      setLoans(false);
      setClick(true);
    }
  };

  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  return (
    <>
      {openModal && (
        <CustomModal handleClose={handleClose} openModal={openModal}>
          <div className="h-screen overflow-y-scroll p-10 sm:h-[70vh] sm:max-h-[500px]">
            <Investment handleClose={handleClose} />
          </div>
        </CustomModal>
      )}
      <div className={click ? "header-body" : "header-cont"}>
        <div className="header-wrapper">
          <Link to="/">
            <img
              className="logo"
              style={{
                width: !click && "100%",
              }}
              src={click ? Logo2 : click === false ? WhiteLogo : null}
              alt="Logo"
            />
          </Link>
          <div
            className="menu-icon"
            onClick={() => {
              setClick(!click);
            }}
          >
            {click ? (
              <img src={Menu} alt="menu" />
            ) : click === false ? (
              <img src={Times} alt="times" />
            ) : null}
          </div>
          <div className="header-links">
            {links?.map((item, index) => {
              if (item.subnav !== undefined) {
                return (
                  <div className="subnav" key={index}>
                    <p
                      style={{
                        color:
                          pathname.charAt(0) ===
                          item.title.toLowerCase().charAt(0)
                            ? "#029247"
                            : "#4C4D50",
                        fontWeight:
                          pathname.charAt(0) ===
                          item.title.toLowerCase().charAt(0)
                            ? "700"
                            : "500",
                        borderBottom:
                          pathname.charAt(0) ===
                          item.title.toLowerCase().charAt(0)
                            ? "2px solid #029247"
                            : "none",
                      }}
                      className="nav-links"
                      onClick={() => changeLink(item.title)}
                    >
                      {item.title}
                      <span>
                        {item.title === "Loans" ? (
                          loans ? (
                            <img src={ArrowUp} alt="arrow" />
                          ) : loans === false ? (
                            <img src={ArrowDown2} alt="arrow" />
                          ) : null
                        ) : item.title === "Investments" ? (
                          investments ? (
                            <img src={ArrowUp} alt="arrow" />
                          ) : investments === false ? (
                            <img src={ArrowDown2} alt="arrow" />
                          ) : null
                        ) : null}
                      </span>
                    </p>
                    <OutsideClick
                      onClickOutside={() => {
                        setLoans(false);
                        setInvestments(false);
                      }}
                    >
                      {item.title === "Loans" ? (
                        loans ? (
                          <div className="dropdown-container">
                            {item.subnav?.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="dropdown-single !-mt-5 overflow-scroll"
                                >
                                  <div className="dropdown-logo !h-7 !w-7 xl:!h-12 xl:!w-12 ">
                                    <img src={item.icon} alt="icon" />
                                  </div>
                                  <div
                                    className="dropdown-text"
                                    onClick={() => {
                                      setLoans(false);
                                    }}
                                  >
                                    {item.link.slice(-4) === "com/" ? (
                                      <a
                                        href={item.link}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        <h2>{item.title}</h2>
                                        <p>{item.text}</p>
                                      </a>
                                    ) : (
                                      <NavLink to={item.link}>
                                        <h2>{item.title}</h2>
                                        <p>{item.text}</p>
                                      </NavLink>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        ) : null
                      ) : null}
                    </OutsideClick>
                    <OutsideClick
                      onClickOutside={() => {
                        handle();
                      }}
                    >
                      {item.title === "Investments" ? (
                        investments ? (
                          <div className="dropdown-container">
                            {item.subnav?.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  className="dropdown-single !-mt-5 "
                                >
                                  <div className="dropdown-logo !h-7 !w-7 xl:!h-12 xl:!w-12 2xl:!h-14 2xl:!w-14">
                                    <img
                                      src={item.icon}
                                      className="w-full"
                                      alt="icon"
                                    />
                                  </div>
                                  <div
                                    className="dropdown-text"
                                    onClick={() => {
                                      setInvestments(false);
                                    }}
                                  >
                                    <NavLink to={item.link}>
                                      <h2>{item.title}</h2>
                                      <p>{item.text}</p>
                                    </NavLink>
                                  </div>
                                </div>
                              );
                            })}

                            <NavLink
                              onClick={() => {
                                setInvestments(false);
                              }}
                              to="/createinvestment"
                              className="z-50 mx-auto flex w-3/4 cursor-pointer items-center justify-center rounded-full bg-[#029247] px-6 py-3 font-semibold capitalize !text-white"
                            >
                              <p>create investment</p>
                            </NavLink>
                          </div>
                        ) : null
                      ) : null}
                    </OutsideClick>
                  </div>
                );
              } else {
                return (
                  <NavLink className="nav-links" to={item.link} key={index}>
                    {item.title}
                  </NavLink>
                );
              }
            })}

            {/* <p onClick={handleOpen} className="apply__now !h-[45px] !w-[120px]">
              Apply Now
            </p> */}
            {/* <img
              style={{
                display: !click && "none",
                marginLeft: "2em",
                height: "90px",
                // width:'0px'
              }}
              src={line}
              alt="line"
            /> */}
            {pathname === "scholarship-application" ? (
              
              <a
                style={{
                  textDecoration: "none",
                  color: "#029247",
                  border: "1px solid #029247",
                  borderRadius: "100px",
                  padding: ".7em",
                  marginLeft: "1em",
                }}
                className="cursor-pointer"
                href={
                  device === "andriod"
                    ? "https://play.google.com/store/apps/details?id=com.fastcredit"
                    : null
                }
                target="_blank"
                rel="noreferrer"
              >
                Get Fast Credit App
              </a>
            ) : (
              <button>
                <a
                  style={{
                    textDecoration: "none",
                    color: "white",
                  }}
                  href="https://play.google.com/store/apps/details?id=com.fastcredit"
                  target="_blank"
                  rel="noreferrer"
                >
                  Get Fast Credit App
                </a>
              </button>
            )}
            {pathname === "scholarship-application" ? (
              <Link to="/scholarship-application/apply">
                <p className="rounded-full p-3 ml-5 !h-[45px] !w-[120px] bg-[#029247] flex items-center justify-center text-white">
                  Apply Now
                </p>
              </Link>
            ) : (
              <Link to="/scholarship-application">
                <p className="apply__now !h-[45px] !w-[120px]"> Scholarship</p>
              </Link>
            )}
          </div>
        </div>
        <div
          className={
            click ? "header-link" : click === false ? " header-active" : null
          }
        >
          {links?.map((item, index) => {
            if (item.subnav !== undefined) {
              return (
                <div className="subnav" key={index}>
                  <p
                    className="nav-links"
                    onClick={() => {
                      if (item.title === "Loans") {
                        setLoans(!loans);
                      } else if (item.title === "Investments") {
                        setInvestments(!investments);
                      }
                    }}
                  >
                    {item.title}
                    <span>
                      {item.title === "Loans" ? (
                        loans ? (
                          <img src={ArrowUp} alt="arrow" />
                        ) : loans === false ? (
                          <img src={ArrowDown} alt="arrow" />
                        ) : null
                      ) : item.title === "Investments" ? (
                        investments ? (
                          <img src={ArrowUp} alt="arrow" />
                        ) : investments === false ? (
                          <img src={ArrowDown} alt="arrow" />
                        ) : null
                      ) : null}
                    </span>
                  </p>
                  <OutsideClick
                    onClickOutside={() => {
                      setLoans(false);
                    }}
                  >
                    {item.title === "Loans" ? (
                      loans ? (
                        <div className="dropdown-container">
                          {item.subnav?.map((item, index) => {
                            return (
                              <div key={index} className="dropdown-single">
                                <div className="dropdown-logo">
                                  <img src={item.icon} alt="icon" />
                                </div>
                                <div
                                  className="dropdown-text"
                                  onClick={() => {
                                    setLoans(false);
                                  }}
                                >
                                  {item.link.slice(-4) === "com/" ? (
                                    <a
                                      href={item.link}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      <h2>{item.title}</h2>
                                      <p>{item.text}</p>
                                    </a>
                                  ) : (
                                    <NavLink to={item.link}>
                                      <h2>{item.title}</h2>
                                      <p>{item.text}</p>
                                    </NavLink>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : null
                    ) : null}
                  </OutsideClick>
                  <OutsideClick
                    onClickOutside={() => {
                      setInvestments(false);
                    }}
                  >
                    {item.title === "Investments" && investments ? (
                      <div className="dropdown-container">
                        {item.subnav?.map((item, index) => {
                          return (
                            <div key={index} className="dropdown-single">
                              <div className="dropdown-logo">
                                <img src={item.icon} alt="icon" />
                              </div>
                              <div
                                className="dropdown-text"
                                onClick={() => {
                                  setInvestments(false);
                                }}
                              >
                                <NavLink to={item.link}>
                                  <h2>{item.title}</h2>
                                  <p>{item.text}</p>
                                </NavLink>
                              </div>
                            </div>
                          );
                        })}
                        <NavLink
                          to="/createinvestment"
                          onClick={() => {
                            setInvestments(false);
                          }}
                          style={{
                            zIndex: "1200",
                          }}
                          className="z-50 my-5 flex w-2/3 cursor-pointer items-center justify-center rounded-full bg-white px-6 py-2 font-semibold capitalize text-[#029247] sm:w-3/4 xl:w-1/2"
                        >
                          create investment
                        </NavLink>
                      </div>
                    ) : null}
                  </OutsideClick>
                </div>
              );
            } else {
              return (
                <NavLink className="nav-links" to={item.link} key={index}>
                  {item.title}
                </NavLink>
              );
            }
          })}
          {pathname === "scholarship-application" ? (
            <Link to="/scholarship-application/apply">
              <p className="nav-links">Apply Now</p>
            </Link>
          ) : (
            <Link to="/scholarship-application">
              <p className="nav-links">Scholarship</p>
            </Link>
          )}
          {/* <p onClick={buyNow} className="nav-links">
            Buy Now, Pay Later
          </p> */}
          {/* <p onClick={handleOpen} className="nav-links">
            Apply Now
          </p> */}

          <button
            style={{
              border: "none",
              outline: "none",
              borderRadius: "100px",
            }}
          >
            <a
              style={{
                textDecoration: "none",
                color: " #029247",
              }}
              href={
                device === "andriod"
                  ? "https://play.google.com/store/apps/details?id=com.fastcredit"
                  : null
              }
              target="_blank"
              rel="noreferrer"
            >
              Get Fast Credit App
            </a>
          </button>
        </div>
      </div>
    </>
  );
};

export default Header;
