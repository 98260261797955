import Footer from "../../components/footer/footer";
import Head from "../../components/head/head";
import Header from "../../components/header/header";
import "./policy.css";

const CookiesPolicy = () => {
  return (
    <div className="policy">
      <Header />
      <div className="header">
        <Head h2="Cookies Policy" />
        <p>
          This Cookies Policy (Policy) explains how we use cookies and the
          choices you have. Except as otherwise stated in this Policy, our
          Privacy Policy will apply to our processing of data that we collect
          via cookies. You can access our Privacy Policy via this{" "}
          <a
            className="text-green-500 underline"
            target="_blank"
            style={{
              marginLeft: "2px",
            }}
            href="/policy"
          >
            link.
          </a>
        </p>
        <p>
          By using our website (by clicking, navigating or scrolling), you are
          consenting to our use of cookies in accordance with this Policy. If
          you do not agree to our use of cookies, you can prevent the
          installation of cookies through the settings of your browser or not
          use our website at all. However, if you disable the use of cookies,
          this might cause some parts of this website not to function properly
          for you and it may impact your user experience on this site.
        </p>
      </div>

      <section className="policy__content">
        <h2 className="policy__title">What are Cookies?</h2>
        <p>
          Cookies are messages or small files that are placed on your web
          browser when you visit an internet site. Cookies are useful and do a
          lot of different things, including allowing a website to recognize a
          user’s device, improving online experience etc.
        </p>

        <h2 className="policy__title">How and Why do we Use Cookies? </h2>

        <p>
          We use cookies when you access our websites and our online products
          and services to make the site work better and to help us understand
          how you use our site.
        </p>
        <p>
          Cookies enable us to offer tailored products and to understand the
          information we receive about you, including information about your use
          of other websites and apps, whether or not you have an account with
          us.
        </p>
        <p>
          Cookies help us provide, protect and improve our products and
          services, by personalizing, tailoring and measuring the services and
          products we provide for satisfactory and safe experience. We also use
          them to help authentication of user, assess performance and
          functionality of our online products and services, and analytical
          research.
        </p>
      </section>
      <Footer />
    </div>
  );
};

export default CookiesPolicy;
