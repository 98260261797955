import axios from "axios";

export const URL = "https://apicore.fastcredit-ng.com/v1";

export const api = async () => {
  const url = `${URL}/fclapi/login`;
  const payload = {
    Client_key: "fcluser",
    Client_secret: "f@3*c#4luser",
  };
  const response = await axios
    .post(url, payload)
    .then((res) => {
      localStorage.setItem("token", JSON.stringify(res.data.token));
      return res.data;
    })
    .catch((err) => console.log(err));
  return response;
};

export const getApi = async (url) => {
  let data;
  if (typeof window !== "undefined") {
    data = JSON.parse(localStorage.getItem("token"));
  }
  const options = {
    headers: {
      Authorization: `Bearer ${data}`,
      "Access-Control-Allow-Origin": "*",
    },
  };
  try {
    return await axios
      .get(url, options)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        console.log(err.message);
      });
  } catch (err) {
    return err.response;
  }
};

export const postApi = async (url, payload) => {
  let data;
  if (typeof window !== "undefined") {
    data = JSON.parse(localStorage.getItem("token"));
  }
  const options = {
    headers: {
      Authorization: `Bearer ${data}`,
      "Access-Control-Allow-Origin": "*",
      "X-API-KEY": "FCL-LOAN-REQUEST",
    },
  };
  const response = await axios
    .post(url, payload, options)
    .then((res) => {
      if (res.status === 200) {
        return res.data;
      } else {
        console.log("err");
      }
    })
    .catch((err) => {
      console.log("error msg: ", err);
      return err;
    });
  return response;
};

export const putApi = async (url, payload) => {
  let data;
  if (typeof window !== "undefined") {
    data = JSON.parse(localStorage.getItem("token"));
  }
  const options = {
    headers: {
      Authorization: `Bearer ${data}`,
      "Access-Control-Allow-Origin": "*",
    },
  };
  const response = await axios
    .put(url, payload, options)
    .then((res) => {
      // if (res.status === 200) {
      //   return res.data;
      // } else {
      //   console.log("err");
      // }
      return res.data;
    })
    .catch((err) => {
      console.log("error msg: ", err.message);
      return err.message;
    });
  return response;
};
