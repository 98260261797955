import React from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import "./grant.css";
import Grant from "../../components/Grant";

export default function GrantApp() {
  return (
    <>
      <Header />
      {/* <section className="grant">
        <img src={bg} className="img w-full" alt="" />
        <span>
          <h2 className="grant_title"> Grant just got better </h2>
          <p className="grant_subtitle">
            get access to fund your education within minutes 🚀
          </p>
        </span>
      </section> */}
      {/* <GrantForm /> */}
      <Grant />
      <Footer />
    </>
  );
}
