import "./head.css";

const Head = ({ h2, p }) => {
  return (
    <div className="head-container">
      <h2>{h2}</h2>
      {p === undefined ? null : <p>{p}</p>}
    </div>
  );
};

export default Head;
