import { memo } from "react";
import "./Contact.css";

const InputForm = ({
  handleChange,
  placeholder,
  name,
  type,
  label,
  value,
  length,
  onInput,
  pattern,
  sublabel,max
}) => {
  return (
    <div className="input__form">
      <label htmlFor={label} className="label ">
        {label}
      </label>
      {sublabel && (
        <label htmlFor={sublabel} className="text-[10px] text-gray-500">
          {sublabel}
        </label>
      )}
      <input
        style={{
          width: name === "fullname" ? "auto" : "auto",
          borderBottom: value ? "2px solid #029247" : "none",
          background: value ? "#F2FCF5" : "#F6F6F6",
        }}
        type={type}
        onChange={handleChange}
        className="input rounded"
        placeholder={placeholder}
        name={name}
        value={value}
        onInput={onInput}
        pattern={pattern}
        maxLength={length}
        max={max}
      />
    </div>
  );
};

export default memo(InputForm);
