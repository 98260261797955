import { createSlice } from "@reduxjs/toolkit";
import { Toast } from "../components/Toast";

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    products: [],
    quantity: 0,
    total: 0,
    travel: null,
  },
  reducers: {
    addTravelDetails: (state, action) => {
      state.travel = action.payload;
    },
    addProduct: (state, action) => {
      const exist = state.products.find(
        (product) => product.id === action.payload.id
      );
      if (exist) {
        exist.quantity += 1;
        exist.total = exist.quantity * exist.price;
      } else {
        state.products.push(action.payload);
        state.quantity += 1;
        state.total += action.payload.price * action.payload.quantity;
        localStorage.setItem("cart", JSON.stringify(state.products));
      }
    },

    getCartProduct: (state) => {
      const cart = JSON.parse(localStorage.getItem("cart"));

      if (cart) {
        state.products = cart;
        state.quantity = cart.length;
        state.total = cart.reduce((acc, curr) => {
          return acc + curr.price * curr.quantity;
        }, 0);
      }
    },

    decreaseCartQuantity(state, action) {
      const itemIndex = state.products.findIndex(
        (cartItem) => cartItem.id === action.payload.id
      );
      const cart = JSON.parse(localStorage.getItem("cart"));

      const newCart = cart.filter((item) => {
        return item.id === action.payload.id;
      });
      state.products[itemIndex].price =
        state.products[itemIndex].price - newCart[0]?.price;
      if (state.products[itemIndex].quantity > 1) {
        state.products[itemIndex].quantity -= 1;
      } else if (state.products[itemIndex].quantity === 1) {
        const inproducts = state.products.filter(
          (cartItem) => cartItem.id !== action.payload.id
        );
        state.products = inproducts;
        state.quantity = state.quantity - 1;
        Toast({ title: `Item removed from the cart!`, error: true });
      }
      localStorage.setItem("products", JSON.stringify(state.products));
    },

    increaseCartQuantity(state, action) {
      const cart = JSON.parse(localStorage.getItem("cart"));
      const newCart = cart.filter((item) => {
        return item.id === action.payload.id;
      });

      const itemIndex = state.products.findIndex(
        (cartItem) => cartItem.id === action.payload.id
      );

      state.products[itemIndex].quantity += 1;
      state.products[itemIndex].price =
        newCart[0]?.price * state.products[itemIndex].quantity;
      localStorage.setItem("products", JSON.stringify(state.products));
    },

    removeCartProduct: (state, action) => {
      const cart = JSON.parse(localStorage.getItem("cart"));
      const newCart = cart.filter((item) => {
        return item.id !== action.payload;
      });
      state.products = newCart;
      state.quantity = newCart.length;
      state.total = newCart.reduce((acc, curr) => {
        return acc + curr.price * curr.quantity;
      }, 0);
      localStorage.setItem("cart", JSON.stringify(newCart));
    },

    reset: (state) => {
      state.products = [];
      state.quantity = 0;
      state.total = 0;
      localStorage.removeItem("cart");
    },
  },
});

export const {
  addProduct,
  reset,
  addTravelDetails,
  increaseCartProduct,
  getCartProduct,
  removeCartProduct,
  decreaseCartQuantity,
  increaseCartQuantity,
} = cartSlice.actions;

export default cartSlice.reducer;
